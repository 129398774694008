
import SearchResultTableCellProperty from "~/components/explore/SearchResultTableCellProperty.vue";
import SearchResultTableCellCompany from "~/components/explore/SearchResultTableCellCompany.vue";

export default {

  components: {
    SearchResultTableCellProperty,
    SearchResultTableCellCompany,
  },

  props: {
    loading: {
      type: Boolean,
      required: true
    },
    results: {
      type: Array,
      required: true
    }
  },

  computed: {
    properties: function() {
      return this.results.filter(result => result.typeOf === 'PROPERTY');
    },

    companies: function() {
      return this.results.filter(result => result.typeOf === 'COMPANY');
    },
    
    headers: function () {
      return this.results[0].keyFigureLabels
    }
  },

  methods: {
   saveToList(result) {
      this.$emit('save-to-list', result);
    },

    copyToClipboard(keyFigureLabels, keyFigures, addressText) {
      this.$emit('copy-to-clipboard', keyFigureLabels, keyFigures, addressText);
    },

    ownerClicked(owner) {
      this.$emit('click-owner', owner);
    }
  }
}
